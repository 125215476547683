<template>
  <div id="About">
    <div id="About_Cover">
      <img :src="GetFullPath(about_cover)" :alt="about_cover_alt" />
    </div>
    <div class="row">
      <div class="brand_story">
        <h2 class="title_font">ABOUT US</h2>
        <div class="content content_font" v-html="about_content"></div>
      </div>

      <div class="certification">
        <h2 class="title_font">Certification &Test Reports</h2>
        <div class="certification_content">
          <!-- <div
            class="content content_font"
            v-html="about_certification_content"
          ></div> -->
          <img
            class="certification_img"
            :src="GetFullPath(about_certification_image)"
          />
        </div>
      </div>

      <div class="company_info_table">
        <h2 class="title_font">COMPANY INFO</h2>
        <div class="info_group content_font">
          <label>Company Name</label>
          <p>{{ company_data.company_name.Content }}</p>
        </div>
        <div class="info_group content_font">
          <label>Tax ID</label>
          <p>{{ company_data.company_line_id.Content }}</p>
        </div>
        <div class="info_group map content_font">
          <label>Company Address</label>
          <div>
            <div v-html="company_data.company_google_map.Content"></div>
            <p>{{ company_data.company_address.Content }}</p>
          </div>
        </div>
        <div class="info_group content_font">
          <label>Company Tel</label>
          <p>{{ company_data.company_phone.Content }}</p>
        </div>
        <!-- <div class="info_group content_font">
          <label>資本額</label>
          <p>{{ company_data.company_capital.Content }}</p>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { GetMetaData } from '@/common/meta';
export default {
  name: 'About',
  data() {
    return {
      about_content: '',
      about_cover: '',
      about_cover_alt: '',
      about_certification_content: '',
      about_certification_image: '',
      meta_data: null,
    };
  },
  methods: {
    async LoadData() {
      this.$store.commit('SetLoading', true);
      let response = await this.SendData('/column', 'post', {
        Data: [
          'about_content',
          'about_cover',
          'about_cover_alt',
          'about_certification',
        ],
      });
      this.$store.commit('SetLoading', false);
      if (response != 'error') {
        console.log(response);
        this.about_content = response.about_content.Content;
        this.about_cover = response.about_cover.Image;
        this.about_cover_alt = response.about_cover_alt.Content;
        this.about_certification_content = response.about_certification.Content;
        this.about_certification_image = response.about_certification.Image;
        this.meta_data = GetMetaData(
          'About',
          this.about_content.replace(/<[^>]+>/g, ''),
          ''
        );
      }
    },
  },
  created() {
    this.LoadData();
  },
  computed: {
    company_data() {
      return this.$store.state.company_info;
    },
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
